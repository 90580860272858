import "./global.css";

import { type ResolveMentionSuggestionsArgs } from "@liveblocks/client";
import { LiveblocksProvider } from "@liveblocks/react/suspense";
import { Links, Meta, Scripts, ScrollRestoration, useLocation, useOutlet } from "@remix-run/react";
import { posthog } from "posthog-js";
import { useEffect } from "react";
import { createHead } from "remix-island";

import unknownIconUrl from "./assets/unknown.svg";
import { EditorProvider } from "./components/EditorProvider/EditorProvider.js";
import { userLoader } from "./data/users.js";
import { type User, type UserId } from "./types/entity.js";
import { apiLiveblocksAutocomplete } from "./utils/route/urls.js";

const resolveUsers = async ({ userIds }: { userIds: string[] }) => {
	const users = (await userLoader.loadMany(userIds as UserId[])).filter((u): u is User => !!u && !(u instanceof Error));

	return userIds.map((userId) => {
		const user = users.find((u) => u.id === userId);
		if (!user) {
			return undefined;
		}

		return {
			name: user.name,
			color: "blue",
			avatar: user.picture ?? unknownIconUrl,
		};
	});
};

const resolveMentionSuggestions = async ({ roomId, text }: ResolveMentionSuggestionsArgs) => {
	try {
		const url = new URL(apiLiveblocksAutocomplete(), window.location.origin);
		url.searchParams.set("roomId", roomId);
		url.searchParams.set("text", text);
		const response = await fetch(url.toString());
		if (response.ok) {
			const result = (await response.json()) as { users: readonly User[] };
			return result.users.map((user) => user.id);
		}

		return [];
	} catch (e) {
		console.log(e);
		return [];
	}
};

export const Head = createHead(() => (
	<>
		<meta charSet="utf-8" />
		<meta name="viewport" content="width=device-width, initial-scale=1" />
		<link rel="icon" href="/favicon.small.png" />
		<Meta />
		<Links />
	</>
));

export default function App() {
	const outlet = useOutlet();

	const location = useLocation();
	useEffect(() => {
		posthog.capture("$pageview");
	}, [location]);

	return (
		<>
			<Head />
			<LiveblocksProvider
				authEndpoint="/api/liveblocks-auth"
				throttle={16}
				resolveUsers={resolveUsers}
				resolveMentionSuggestions={resolveMentionSuggestions}
			>
				<EditorProvider>{outlet}</EditorProvider>
			</LiveblocksProvider>
			<ScrollRestoration />
			<Scripts />
		</>
	);
}
